import Glide from "@glidejs/glide";

$(document).ready(function ($) {
  if ($(window).width() < 768) {
    var glide = new Glide(".glide", {
      type: "slider",
      focusAt: "center",
      gap: "15px",
      perView: 1.3,
      peek: {
        before: 0,
        after: 0,
      },
      animationDuration: 1000,
    });
    glide.mount();
  } else {
    var glide = new Glide(".glide", {
      type: "slider",
      focusAt: "center",
      gap: "60px",
      perView: 1.3,
      peek: {
        before: 0,
        after: 0,
      },
      animationDuration: 1000,
    });

    glide.mount();
  }
});
jQuery(window).resize(function () {
  if ($(window).width() < 768) {
    var glide = new Glide(".glide", {
      type: "slider",
      focusAt: "center",
      gap: "15px",
      perView: 1.3,
      peek: {
        before: 0,
        after: 0,
      },
      animationDuration: 1000,
    });
    glide.mount();
  } else {
    var glide = new Glide(".glide", {
      type: "slider",
      focusAt: "center",
      gap: "60px",
      perView: 1.3,
      peek: {
        before: 0,
        after: 0,
      },
      animationDuration: 1000,
    });

    glide.mount();
  }
});

/*var glide = new Glide(".glide-mob", {
  type: "carousel",
  focusAt: "center",
  gap: 0,
  perView: 1,
  autoplay: 5000,
  animationDuration: 1000,
});
glide.mount();*/
