/**
 *
 * Changer les id des ancres au niveau des "scrollTop" selon vos besoins
 *
 */

var $hamburger = $(".hamburger");
var $mobNav = $("#mobNav");

$hamburger.on("click", function (e) {
  $hamburger.toggleClass("is-active");
  $mobNav.toggleClass("mobNavOpen");
});

/*$("#logo-sticky-menu").click(function () {
  $("html, body").animate(
    {
      scrollTop: $("#top").offset().top - 70,
    },
    500
  );
});

// DESK
$("#nav1").click(function () {
  $("html, body").animate(
    {
      scrollTop: $("#tech").offset().top - 50,
    },
    500
  );
});
$("#nav2").click(function () {
  $("html, body").animate(
    {
      scrollTop: $("#spaceti").offset().top - 50,
    },
    500
  );
});

// MOB
$("#nav1Mob").click(function () {
  $("html, body").animate(
    {
      scrollTop: $("#tech").offset().top - 160,
    },
    500
  );
  $hamburger.removeClass("is-active");
  $mobNav.removeClass("mobNavOpen");
  $("html, body").css({
    overflow: "auto",
    height: "auto",
  });
});

$("#nav2Mob").click(function () {
  $("html, body").animate(
    {
      scrollTop: $("#spaceti").offset().top - 40,
    },
    500
  );
  $hamburger.removeClass("is-active");
  $mobNav.removeClass("mobNavOpen");
  $("html, body").css({
    overflow: "auto",
    height: "auto",
  });
});
$("#enveloppe").click(function () {
  $("html, body").animate(
    {
      scrollTop: $("#form").offset().top - 40,
    },
    500
  );
  $hamburger.removeClass("is-active");
  $mobNav.removeClass("mobNavOpen");
  $("html, body").css({
    overflow: "auto",
    height: "auto",
  });
});
$("#mobBtn").click(function () {
  $("html, body").animate(
    {
      scrollTop: $("#form").offset().top - 40,
    },
    500
  );
  $hamburger.removeClass("is-active");
  $mobNav.removeClass("mobNavOpen");
  $("html, body").css({
    overflow: "auto",
    height: "auto",
  });
});
*/
